<template>
  <MainLayout>
    <div class="row">
      <div class="col-12 d-flex flex-wrap online-id-container">
        <div class="title col-12">
          Online identification
        </div>
        <div class="content col-12" v-if="isPassed">
          <p class="ext-margin ">
            Identification succesfully passed, thank you!
          </p>
          <router-link :to="{ name: 'LifeSituation', params: { id: $route.params.id } }" id="ioNextStep"
            class="btn btn-primary px-4 button-padding">
            Proceed to the next step&nbsp;&nbsp; &rarr;
          </router-link>
        </div>
        <div v-else class="content col-12">
          <p class=" subtitle">
            Important Information for Identification
          </p>
          <p class="ext-margin">
            Welcome to Simplewealth, your reliable partner for digital asset management in Switzerland. Before you can begin your investment experience with us, we require some important documents from you. This step is crucial to ensure your safety and to comply with legal requirements.
          </p>
          <p class="subtitle">
            What You Should Prepare:
          </p>
          <p>- Swiss Utility Bill: Please upload a recent electricity or water bill. This helps us to confirm your residential address.</p>
          <p class="ext-margin">- Proof of Identity: We need a copy of your ID card or driver's license (both sides) or your passport. Ensure that the document is valid and clearly readable.</p>
          <p class="subtitle">
            What Happens Next:
          </p>

          <p>After you have uploaded these documents, we will carefully review your information and forward it to our partner Interactive Brokers. This review is an essential part of our commitment to providing you with a secure and trustworthy service. Interactive Brokers will then decide on the opening of your account. You can expect to receive feedback within a week.</p>
          <p>We thank you for your understanding and cooperation in this important step. If you have any questions or concerns, we are always here to help.</p>

      

          <form>
            <label class="custom-file-input btn btn-primary px-4 button-padding">
              <input type="file" name="passport" @change="onFileChange" ref="passportInput"
                :disabled="passportFilesCount">
              Upload passport
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                v-if="!passportFilesCount">
                <path
                  d="M11 14.9861C11 15.5384 11.4477 15.9861 12 15.9861C12.5523 15.9861 13 15.5384 13 14.9861V7.82831L16.2428 11.0711L17.657 9.65685L12.0001 4L6.34326 9.65685L7.75748 11.0711L11 7.82854V14.9861Z"
                  fill="currentColor" />
                <path d="M4 14H6V18H18V14H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V14Z"
                  fill="currentColor" />
              </svg>

              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                <path
                  d="M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z"
                  fill="currentColor" />
              </svg>
            </label>

            <label class="custom-file-input btn btn-primary px-4 button-padding">
              <input type="file" name="utilitybill" @change="onFileChange" ref="utilitybillInput"
                :disabled="utilitybillFilesCount">
              Upload utility bill
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                v-if="!utilitybillFilesCount">
                <path
                  d="M11 14.9861C11 15.5384 11.4477 15.9861 12 15.9861C12.5523 15.9861 13 15.5384 13 14.9861V7.82831L16.2428 11.0711L17.657 9.65685L12.0001 4L6.34326 9.65685L7.75748 11.0711L11 7.82854V14.9861Z"
                  fill="currentColor" />
                <path d="M4 14H6V18H18V14H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V14Z"
                  fill="currentColor" />
              </svg>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                <path
                  d="M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z"
                  fill="currentColor" />
              </svg>
            </label>
          </form>


        </div>
      </div>
    </div>
  </MainLayout>
</template>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  gap: 1rem;
}

.custom-file-input {
  input {
    display: none;
  }



  display: flex;
  justify-content: space-between;
  align-items: center;
}

.online-id-container {
  margin: 40px 0;
}

.title {
  font-family: "SuisseIntl-Medium", sans-serif;
  font-weight: 500;
  font-size: 33px;
  line-height: 42px;
  letter-spacing: -0.8px;
  color: #090d2e;
  margin-bottom: 32px;
}

.content {
  p {
    font-size: 16px;
    line-height: 24px;
    color: #676d9c;

    &.ext-margin {
      margin-bottom: 32px;
    }

    &.subtitle{
      font-weight: 500;
      font-size: 18px;
      color: #090d2e;
    }
  }
}
</style>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import { mapActions, mapGetters } from "vuex";
import api from "../services/api";

export default {
  components: {
    MainLayout,
  },
  data: () => {
    return {
      timer: null,
      percent: 0,
      isTest: false,
      isPassed: false,
      passportFilesCount: 0,
      utilitybillFilesCount: 0,
    };
  },
  name: "OnlineIdentification",
  computed: {
    ...mapGetters(["onbForm"]),
  },
  methods: {
    ...mapActions(["setValue", "turnOnBlur", "turnOffBlur", "fetchForm"]),

    async onFileChange() {
      let passportInput = this.$refs['passportInput'],
        utilitybillInput = this.$refs['utilitybillInput'],
        formData = new FormData();

      this.passportFilesCount = passportInput.files.length;
      this.utilitybillFilesCount = utilitybillInput.files.length;

      if (passportInput.files.length && utilitybillInput.files.length) {
        formData.append("passport", passportInput.files[0]);
        formData.append("utilitybill", utilitybillInput.files[0]);


        this.$store.state.loader = true;

        try {
          await api.post("/upload/" + this.$route.params.id, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
        } catch (e) {
          console.log(e)
        }
        this.$store.state.loader = false;

        this.$router.push({
          name: "LifeSituation",
          params: {
            id: this.$route.params.id,
          },
        });

      }

    }
  },
  mounted() {
    this.isTest = false;//process.env.NODE_ENV === 'e2e';
    this.isPassed = this.$store.state.form.onlineIdentification.identityAccessKey;

  },
};
</script>